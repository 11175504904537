import ImageOne from "../images/DS28.JPG";
import ImageTwo from "../images/DS26.JPG";
import ImageThree from "../images/DS24.JPG";
import ImageFour from "../images/DS18.JPG";

export const SliderData = [
  {
    title: "Welcome to Dance Savannah",
    label: "More About Us",
    text: "A unique dance studio that offers classes in all genres for both casual, as well as, competitive dancers. We offer classes for any level and all ages.",
    path: "/about",
    image: ImageOne,
    alt: "About Dance Savannah",
  },
  {
    title: "Meet Our Teams",
    label: "The Hive",
    text: "Our dancers work hard and play harder all year-round in preparation of competition season.",
    path: "/teams",
    image: ImageTwo,
    alt: "Dance Savannah Teams",
  },
  {
    title: "Take A Class",
    label: "Class Schedule",
    text: "We offer a variety of dance classes throughout the week ranging from Ballet to Hip Hop.",
    path: "/classes",
    image: ImageThree,
    alt: "Dance Savannah Classes",
  },
  {
    title: "All-Star Teams",
    label: "Try Out for the Team",
    text: "All-Star Dance is a competitive, team-based sport focused on six types of dances (Hip Hop, Jazz, Kick, Pom, Lyrical, and Contemporary) in six different age groups (Try, Mini, Youth, Junior, Senior, and Open). It is governed by the United States All Stars Federation (USASF).",
    path: "/register",
    image: ImageFour,
    alt: "Dance Savannah Classes",
  },
];
