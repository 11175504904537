import ImageOne from "../images/Ty.JPG";
import ImageTwo from "../images/DS20.jpg";

export const InfoData = {
  heading: "About Us",
  pOne: "When you join The Hive at Dance Savannah, you become a part of the family. Our team reflects our vision to build character, ability, and community.",
  pTwo: "Our Head Coach/Founder, TySheena Jordan, has a lifetime of experience with cheer, dance, choreography, competitions and leadership. Our team of coaches is further proof of our commitment to variety and excellence. ",
  image: ImageOne,
  buttonLabel: "Meet Our Teams",
  reverse: false,
  delay: 100,
  link: "/teams",
  target: "",
};

export const InfoDataTwo = {
  heading: "Contact Us",
  pOne: "You can reach us at (912) 275-3764",
  pTwo: "Our address is: 7805 Abercorn Street #13 Savannah, GA 31406",
  pThree: "Email: dancesavannah912@gmail.com",
  image: ImageTwo,
  buttonLabel: "Get Directions",
  reverse: true,
  delay: 300,
  link: "/directions",
  target: "_blank",
};
