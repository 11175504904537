import React from "react";
import Classes from "../components/Classes";

const Schedule = () => {
  return (
    <>
      <Classes />
    </>
  );
};

export default Schedule;
