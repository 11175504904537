import React from "react";
import Teams from "../components/Teams";

const OurTeams = () => {
  return (
    <div>
      <Teams />
    </div>
  );
};

export default OurTeams;
