export const menuData = [
  {
    title: "About",
    link: "/about",
  },
  {
    title: "Teams",
    link: "/teams",
  },
  {
    title: "Classes",
    link: "/classes",
  },
];
